import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { LINK } from "store/Queries/Link";
import { MASTER_PAGE } from "store/Queries/MasterPage";
import ResolveField from "components/Layout/ResolveField";
import Layout from "components/Layout";
import Loading from "components/Loading";
import { useParams } from "react-router-dom";
import Header from "components/Layout/Header";
export default function DynemicContent() {
  const { masterPage: masterPageSlug } = useParams();

  const {
    data: { getMasterPageBySlug: masterPage } = { getMasterPageBySlug: null },
    loading,
    error,
  } = useQuery(MASTER_PAGE.getMasterPageBySlug, {
    variables: { slug: masterPageSlug },
  });

  if (loading) return <Loading />;
  if (!masterPage) return "Ön Sayfa Ayarlanmamışx";

  const ThemeComponent = React.lazy(() =>
    import(`../../Themes/${masterPage?.theme.folder}/index.js`)
  );

  return (
    <Layout>
      <React.Suspense fallback={Loading}>
        <ThemeComponent
          content={null}
          ResolveField={ResolveField}
          fields={masterPage?.fields.reduce((field, item) => {
            field[item.name] = item;
            return field;
          }, {})}
        />
      </React.Suspense>
    </Layout>
  );
}

// export default function DynemicContent({ id }) {
//   const {
//     data: { getMasterPageById: masterPage } = { getMasterPageById: null },
//     loading,
//     error,
//   } = useQuery(MASTER_PAGE.getMasterPageById, {
//     variables: { id },
//   });

//   if (loading) return "Yükleniyor...";
//   if (!masterPage) return "Ön Sayfa Ayarlanmamış";

//   const ThemeComponent = React.lazy(() =>
//     import(`../../Themes/${masterPage?.theme.folder}/index.js`)
//   );

//   return (
//     <Layout>
//       <React.Suspense
//         fallback={
//           <div style={{ width: "100%", height: "100vh", background: "red" }}>
//             Loading...
//           </div>
//         }>
//         <ThemeComponent
//           content={"null"}
//           ResolveField={ResolveField}
//           fields={masterPage?.fields.reduce((field, item) => {
//             field[item.name] = item;
//             return field;
//           }, {})}
//         />
//       </React.Suspense>
//     </Layout>
//   );
// }
