import { gql } from "@apollo/client";
import { LANGUAGE_FIELD_FRAGMENT } from "../Fragment/Language";
import { COMPONENT_ACTIVE_ITEM_FRAGMENT } from "./ComponentActiveItem";
import { CONTENT_FRAGMENT } from "./Content";
import { MENU_FRAGMENT } from "./Menu";

export const MASTER_PAGE_FRAGMENT = gql`
  fragment MasterPageFragment on MasterPage {
    id
    name
    slug {
      ...LanguageFieldFragment
    }
    theme {
      id
      name
      folder
    }
    fields {
      name
      props {
        fluid
        className
        elementType
        embed
      }
      cols {
        props {
          sm {
            span
            offset
          }
          md {
            span
            offset
          }
          lg {
            span
            offset
          }
          xl {
            span
            offset
          }
          className
        }
        children {
          type
          props {
            bsPrefix
            className
          }
          data {
            ... on ComponentActiveItem {
              ...ComponentActiveItemFragment
            }
            ... on Menu {
              ...MenuFragment
            }
            ... on Content {
              ...ContentFragment
            }
          }
        }
      }
    }
  }
  ${LANGUAGE_FIELD_FRAGMENT}
  ${COMPONENT_ACTIVE_ITEM_FRAGMENT}
  ${MENU_FRAGMENT}
  ${CONTENT_FRAGMENT}
`;
