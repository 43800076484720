import { gql } from "@apollo/client";
import { LANGUAGE_FIELD_FRAGMENT } from "../Fragment/Language";
import { CONTENT_FRAGMENT } from "./Content";
import { COMPONENT_ACTIVE_ITEM_FRAGMENT } from "./ComponentActiveItem";

export const LINK_FRAGMENT = gql`
  fragment LinkFragment on Link {
    id
    masterPage {
      id
      name
    }
    title {
      ...LanguageFieldFragment
    }
    slug {
      ...LanguageFieldFragment
    }
    description {
      ...LanguageFieldFragment
    }
    metaTitle {
      ...LanguageFieldFragment
    }
    metaDescription {
      ...LanguageFieldFragment
    }
    metaKeywords {
      ...LanguageFieldFragment
    }
    pageTitle {
      ...LanguageFieldFragment
    }
    titleStatus
    pageTitleStatus
    icon
    image
    status
    type
    props {
      className
    }
    externalLink
    data {
      ... on Content {
        ...ContentFragment
      }
      ... on ComponentActiveItem {
        ...ComponentActiveItemFragment
      }
    }
  }

  ${CONTENT_FRAGMENT}
  ${COMPONENT_ACTIVE_ITEM_FRAGMENT}
  ${LANGUAGE_FIELD_FRAGMENT}
`;
