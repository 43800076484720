import { gql } from "@apollo/client";
import { LANGUAGE_FRAGMENT } from "../Fragment/Language";

export const LANGUAGE = {
  activeLanguage: gql`
    query ActiveLanguage {
      activeLanguage {
        ...LanguageFragment
      }
    }
    ${LANGUAGE_FRAGMENT}
  `,
  get: gql`
    query GetLanguage {
      languages {
        ...LanguageFragment
      }
    }
    ${LANGUAGE_FRAGMENT}
  `,
};
