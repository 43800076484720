import React from "react";
import { Spinner, Image } from "react-bootstrap";
import "./style.css";
export default function () {
  return (
    <div class="preloader">
      <Spinner
        animation="grow"
        variant={"light"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Image src={"/loading-icon.png"} width={50} />
      </Spinner>
    </div>
  );

  return (
    <div class="preloader">
      <div class="bookshelf-wrapper">
        <ul class="books-list">
          <li class="book-item first"></li>
          <li class="book-item second"></li>
          <li class="book-item third"></li>
          <li class="book-item fourth"></li>
          <li class="book-item fifth"></li>
          <li class="book-item sixth"></li>
        </ul>
        <div class="shelf"></div>
      </div>
    </div>
  );
  // return (
  // <Spinner animation="border" role="status">
  //   <span className="sr-only">Loading...</span>
  // </Spinner>
  // );
}
