// import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

// import { API_URL } from "../config";

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: new HttpLink({
//     uri: API_URL,
//   }),
// });

// export default client;

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { API_URL } from "../config";

const linkWithFileUpload = createUploadLink({
  uri: API_URL,
});

// const httpLink = new HttpLink({ uri: API_URL });

// const authLink = setContext((_, { headers }) => {
//   const token = `${localStorage.getItem("DISPATENT_BLOG_TOKEN")}`;
//   return {
//     headers: {
//       ...headers,
//       "active-language": localStorage.getItem("LANGUAGE") || null,
//       authorization: token || null,
//     },
//   };
// });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = `${localStorage.getItem("DISPATENT_BLOG_TOKEN")}`;
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token || null,
      "active-language": localStorage.getItem("LANGUAGE") || null,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  // link: concat(authMiddleware, httpLink),
  link: concat(authMiddleware, linkWithFileUpload),
  // link: authLink.concat(linkWithFileUpload),
});

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: new HttpLink({
//     uri: API_URL,
//   }),
// });

export default client;
