import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import "./style.scss";
export default function NoMatch() {
  return (
    <Layout>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>Oops!</h2>
          <p>Üzgünüz, aradığınız sayfayı bulamadık!</p>
          <a href="/">Anasayfa</a>
        </div>
      </div>
    </Layout>
  );
}
