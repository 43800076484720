import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { LANGUAGE } from "../../store/Queries/Language";
import Loading from "./Loading";

export default (Component) => (props) => {
  const [loading, setLoading] = useState(true);
  const [
    getActiveLanguage,
    { data: { activeLanguage } = { activeLanguage: null } },
  ] = useLazyQuery(LANGUAGE.activeLanguage);

  useEffect(() => {
    if (!window.localStorage.getItem("LANGUAGE")) {
      getActiveLanguage();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (activeLanguage) {
      window.localStorage.setItem("LANGUAGE", activeLanguage.key);
      setLoading(false);
    }
  }, [activeLanguage]);

  if (loading) return <Loading />;
  return <Component {...props} />;
};
