import { gql } from "@apollo/client";

export const LANGUAGE_FRAGMENT = gql`
  fragment LanguageFragment on Language {
    id
    key
    title
    icon
    active
  }
`;

export const LANGUAGE_FIELD_FRAGMENT = gql`
  fragment LanguageFieldFragment on LanguageField {
    tr
    en
  }
`;
