import React from "react";
import { Spinner } from "react-bootstrap";
import { gql, useMutation, useQuery } from "@apollo/client";

export default (Component) => (props) => {
  const { data, error, loading } = useQuery(gql`
    query MeBlog {
      meBlog {
        id
        fullName
        username
        email
        role
        createdAt
      }
    }
  `);

  if (loading) {
    return (
      <div>
        <Spinner animation="border" size="sm" />
        Sayfaya yönlendiriliyorsunuz...
      </div>
    );
  }

  if (error) {
    localStorage.removeItem("DISPATENT_BLOG_TOKEN");
    window.location = "/blog";
  }

  return <Component {...props} session={data} />;
};
