import React from "react";
import useLanguage from "../../components/useLanguage";
import { Helmet } from "react-helmet";
import { SETTING } from "store/Queries/Setting";
import { useQuery } from "@apollo/client";

export default function Header({ setting, children }) {
  const { data } = useQuery(SETTING.getSetting);
  const [activeLanguage] = useLanguage();

  return (
    <Helmet>
      <title>
        {setting
          ? `${setting?.metaTitle && setting?.metaTitle[activeLanguage]} ${
              data?.getSetting?.metaTitle &&
              data?.getSetting?.metaTitle[activeLanguage]
            }`
          : data?.getSetting?.metaTitle &&
            data?.getSetting?.metaTitle[activeLanguage]}
      </title>
      <meta
        name="description"
        content={
          setting
            ? setting?.metaDescription &&
              setting?.metaDescription[activeLanguage]
            : data?.getSetting?.metaDescription &&
              data?.getSetting?.metaDescription[activeLanguage]
        }
      />
      <meta
        name="keywords"
        content={
          setting
            ? setting?.metaKeywords && setting?.metaKeywords[activeLanguage]
            : data?.getSetting?.metaKeywords &&
              data?.getSetting?.metaKeywords[activeLanguage]
        }
      />
      <script
        dangerouslySetInnerHTML={{
          __html: setting?.scriptCode,
        }}></script>
    </Helmet>
  );
}
