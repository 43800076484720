import { gql } from "@apollo/client";
import { LANGUAGE_FIELD_FRAGMENT } from "../Fragment/Language";
export const COMPONENT_ACTIVE_ITEM_FRAGMENT = gql`
  fragment ComponentActiveItemFragment on ComponentActiveItem {
    id
    type
    name
    file
    component {
      id
      name
      folder
      values {
        id
        title
        type
        key
        value
        languageValue {
          ...LanguageFieldFragment
        }
        isLanguage
      }
    }
  }
  ${LANGUAGE_FIELD_FRAGMENT}
`;
