import { gql } from "@apollo/client";

import { LANGUAGE_FIELD_FRAGMENT } from "../Fragment/Language";
export const CONTENT_FRAGMENT = gql`
  fragment ContentFragment on Content {
    id
    title {
      ...LanguageFieldFragment
    }
    titleStatus
    status
    content {
      ...LanguageFieldFragment
    }
  }
  ${LANGUAGE_FIELD_FRAGMENT}
`;
