import { gql } from "@apollo/client";
import { MASTER_PAGE_FRAGMENT } from "../Fragment/MasterPage";

export const MASTER_PAGE = {
  getMasterPage: gql`
    query GetMasterPage {
      getMasterPage {
        ...MasterPageFragment
      }
    }
    ${MASTER_PAGE_FRAGMENT}
  `,
  getMasterPageById: gql`
    query GetMasterPageById($id: ID!) {
      getMasterPageById(id: $id) {
        ...MasterPageFragment
      }
    }
    ${MASTER_PAGE_FRAGMENT}
  `,

  getMasterPageBySlug: gql`
    query GetMasterPageBySlug($slug: String!) {
      getMasterPageBySlug(slug: $slug) {
        ...MasterPageFragment
      }
    }
    ${MASTER_PAGE_FRAGMENT}
  `,
};
