import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import importAll from "import-all.macro";
import ScrollToTop from "../helpers/ScrollToTop";
import Home from "./Home";
import Content from "./Content";
import DynemicContent from "components/Content/DynemicContent";

import sessionWrapperHOC from "Packages/Blog/components/sessionWrapperHOC";

// import Loading from "components/Loading/index"
const packagesRouter = importAll.sync("../Packages/**/router.js");

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <>
          <Route exact path="/" component={Home} />
          <Route exact path={"/:slug"} component={Content} />
          <Route exact path={"/content/:id"} component={Content} />
          <Route
            exact
            path={"/:masterPage/:slug"}
            render={(props) => {
              if (
                !props.match ||
                props?.match?.params?.masterPage === "content"
              )
                return null;

              return <DynemicContent {...props} />;
            }}
          />
        </>
      </Switch>
    </Router>
  );
}

export default sessionWrapperHOC(App);
