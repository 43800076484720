import { gql } from "@apollo/client";
import { SETTING_FRAGMENT } from "../Fragment/Setting";

export const SETTING = {
  getSetting: gql`
    query Setting {
      getSetting {
        ...SettingFragment
      }
    }
    ${SETTING_FRAGMENT}
  `,
};
