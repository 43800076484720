import { gql } from "@apollo/client";
import { LANGUAGE_FIELD_FRAGMENT } from "../Fragment/Language";
import { LINK_FRAGMENT } from "../Fragment/Link";
import { CONTENT_FRAGMENT } from "../Fragment/Content";
import { COMPONENT_ACTIVE_ITEM_FRAGMENT } from "./ComponentActiveItem";

export const MENU_FRAGMENT = gql`
  ${LANGUAGE_FIELD_FRAGMENT}
  ${CONTENT_FRAGMENT}
  ${LINK_FRAGMENT}
  ${COMPONENT_ACTIVE_ITEM_FRAGMENT}
  fragment MenuFieldsFragment on Menu {
    id
    title {
      ...LanguageFieldFragment
    }
    titleStatus
    status
    props {
      className
    }
  }

  fragment MenuFragment on Menu {
    ...MenuFieldsFragment
    items {
      type
      data {
        ... on ComponentActiveItem {
          ...ComponentActiveItemFragment
        }
        ... on Content {
          ...ContentFragment
        }
        ... on Link {
          ...LinkFragment
        }
        ... on Menu {
          ...MenuFieldsFragment
          items {
            type
            data {
              ... on ComponentActiveItem {
                ...ComponentActiveItemFragment
              }
              ... on Content {
                ...ContentFragment
              }
              ... on Link {
                ...LinkFragment
              }
              ... on Menu {
                ...MenuFieldsFragment
                items {
                  type
                  data {
                    ... on ComponentActiveItem {
                      ...ComponentActiveItemFragment
                    }
                    ... on Content {
                      ...ContentFragment
                    }
                    ... on Link {
                      ...LinkFragment
                    }
                    ... on Menu {
                      ...MenuFieldsFragment
                      items {
                        type
                        data {
                          ... on ComponentActiveItem {
                            ...ComponentActiveItemFragment
                          }
                          ... on Content {
                            ...ContentFragment
                          }
                          ... on Link {
                            ...LinkFragment
                          }
                          ... on Menu {
                            ...MenuFieldsFragment
                            items {
                              type
                              data {
                                ... on ComponentActiveItem {
                                  ...ComponentActiveItemFragment
                                }
                                ... on Content {
                                  ...ContentFragment
                                }
                                ... on Link {
                                  ...LinkFragment
                                }
                                ... on Menu {
                                  ...MenuFieldsFragment
                                  items {
                                    type
                                    data {
                                      ... on ComponentActiveItem {
                                        ...ComponentActiveItemFragment
                                      }
                                      ... on Content {
                                        ...ContentFragment
                                      }
                                      ... on Link {
                                        ...LinkFragment
                                      }
                                      ... on Menu {
                                        ...MenuFieldsFragment
                                        # items {
                                        #   type
                                        #   data {
                                        #     ... on Content {
                                        #       ...ContentFragment
                                        #     }
                                        #     ... on Link {
                                        #       ...LinkFragment
                                        #     }
                                        #     ... on Menu {
                                        #       ...MenuFieldsFragment
                                        #       ..........
                                        #       ........
                                        #       .....
                                        #     }
                                        #   }
                                        # }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    # items {
    #   type
    #   data {
    #     ... on Menu {
    #       id
    #     }
    #     ... on Link {
    #       ...LinkFragment
    #     }
    #     ... on Content {
    #       ...ContentFragment
    #     }
    #   }
    # }
  }
`;

// ${ LANGUAGE_FIELD_FRAGMENT }
// ${ CONTENT_FRAGMENT }
// ${ LINK_FRAGMENT }

// fragment MenuFieldsFragment on Menu {
//   id
//   title {
//       ...LanguageFieldFragment
//   }
//   titleStatus
//   status
// }

// fragment MenuFragment on Menu {
//     ...MenuFieldsFragment
//   items {
//       ...on Link {
//         ...LinkFragment
//     }
//       ...on Content {
//         ...ContentFragment
//     }
//       ...on Menu {
//         ...MenuFieldsFragment
//       items {
//           ...on Link {
//             ...LinkFragment
//         }
//           ...on Content {
//             ...ContentFragment
//         }
//           ...on Menu {
//             ...MenuFieldsFragment
//           items {
//               ...on Link {
//                 ...LinkFragment
//             }
//               ...on Content {
//                 ...ContentFragment
//             }
//               ...on Menu {
//                 ...MenuFieldsFragment
//               item {
//                   ...on Link {
//                     ...LinkFragment
//                 }
//                   ...on Content {
//                     ...ContentFragment
//                 }
//                   ...on Menu {
//                     ...MenuFieldsFragment
//                   items {
//                       ...on Link {
//                         ...LinkFragment
//                     }
//                       ...on Content {
//                         ...ContentFragment
//                     }
//                       ...on Menu {
//                         ...MenuFieldsFragment
//                       items {
//                           ...on Link {
//                             ...LinkFragment
//                         }
//                           ...on Content {
//                             ...ContentFragment
//                         }
//                           ...on Menu {
//                             ...MenuFieldsFragment
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
